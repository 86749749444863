import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "login",
    component: () => import("@/components/LoginPlataforma.vue"),
  },
  {
    path: "/secuencia-didactica",
    name: "secuencia",
    component: () => import("@/components/SecuenciaDidactica.vue"),
  },
  {
    path: "/asignaturas",
    name: "asignaturas",
    component: () => import("@/components/AsignaturasLista.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/components/PrincipalPage.vue"),
  },
  {
    path: "/tareas/:id",
    name: "tareas",
    component: () => import("@/components/TareasLista.vue"),
  },
  {
    path: "/tarea",
    name: "tarea",
    component: () => import("@/components/TareaVista.vue"),
  },
  {
    path: "/calificaciones",
    name: "calificaciones",
    component: () => import("@/components/CalificacionesPlataforma.vue"),
  },
  {
    path: "/comportamiento-admin",
    name: "comportamiento",
    component: () => import("@/components/ComportamientoAdmin.vue"),
  },
  {
    path: "/boleta",
    name: "boleta",
    component: () => import("@/components/ImprimirCalificaciones.vue"),
  },
  {
    path: "/retroalimentaciones",
    name: "retroalimentaciones",
    component: () => import("@/components/RetroalimentacionesPlataforma.vue"),
  },
  {
    path: "/administrador",
    name: "administrador",
    component: () => import("@/components/AdministradorPlataforma.vue"),
  },
  {
    path: "/plaeud",
    name: "plaedu",
    component: () => import("@/components/EduPlat.vue"),
  },
  {
    path: "/contenido/:id",
    name: "contenido",
    component: () => import("@/components/ContenidoPlataforma.vue"),
  },
  {
    path: "/leccion/:id",
    name: "leccion",
    component: () => import("@/components/LeccionPlataforma.vue"),
  },
  {
    path: "/evaluacion/",
    name: "evaluacion",
    component: () => import("@/components/EvaluacionJueces.vue"),
  },
  {
    path: "/evaluacion-admin/",
    name: "evaluacion-admin",
    component: () => import("@/components/EvaluacionJuecesAdmin.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export default router;
